import { memo } from 'react';
import type { FC } from 'react';

import resets from '../_resets.module.css';
import classes from './LandingPage1.module.css';

interface Props {
  className?: string;
}
/* @figmaId 1:390 */
export const LandingPage1: FC<Props> = memo(function LandingPage1(props = {}) {
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.imageDragInToFill}></div>
      <div className={classes.navigationFooter}>
        <div className={classes.pORTALEGREVISTADOCEU}>
          <div className={classes.textBlock}>PORTALEGRE</div>
          <div className={classes.textBlock2}>VISTA DO CÉU</div>
        </div>
        <div className={classes.divider}></div>
        <div className={classes.paraMaisInformacoesOuAquisicao}>
          <div className={classes.textBlock3}>Para mais informações ou aquisição de obras contacte:</div>
          <div className={classes.textBlock4}>
            <p className={classes.labelWrapper}>
              <span className={classes.label}>telef. +351 913 914 401</span>
            </p>
          </div>
          <div className={classes.textBlock5}>
            <p className={classes.labelWrapper2}>
              <span className={classes.label2}>email </span>
              <a
                className={classes.label3}
                href='mailto:portalegrevistadoceu@gmail.com'
                target='_blank'
                rel='noreferrer'
              >
                portalegrevistadoceu@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className={classes.image1}></div>
      </div>
      <div className={classes.copy}>
        <div className={classes.pORTALEGREVISTADOCEU2}>
          <div className={classes.textBlock6}>PORTALEGRE</div>
          <div className={classes.textBlock7}>
            <p className={classes.labelWrapper3}>
              <span className={classes.label4}>VISTA DO CÉU</span>
            </p>
          </div>
        </div>
        <div className={classes.sobemAsAlturasEMostramNosOQueN}>
          <div className={classes.textBlock8}>
            Sobem às alturas e mostram-nos o que não vemos com os dois pés do chão.
          </div>
          <div className={classes.textBlock9}>
            Os drones trouxeram uma nova dimensão à fotografia, permitindo aos fotógrafos explorar perspectivas únicas e
            capturar imagens impressionantes. 
          </div>
          <div className={classes.textBlock10}>
            No entanto, seu uso requer habilidade, responsabilidade e respeito pelas regras e pelos outros.
          </div>
        </div>
      </div>
      <div className={classes.oSAUTORES}>OS AUTORES</div>
      <div className={classes.nascidoEm1977EmPortalegreCompr}>
        Nascido em 1977 em Portalegre, comprou a sua primeira câmera Reflex em 2009. A sua cidade natal é o foco
        principal das suas fotografias. Em 2021, adquiriu um drone, ampliando a sua perspectiva fotográfica. Foi
        fotógrafo oficial da Federação Portuguesa de Motociclismo por três anos e também se tornou fotógrafo de eventos
        baseado em Portalegre.
      </div>
      <div className={classes.nascidoEm1962JuntoRibeiraDeArr}>
        Nascido em 1962 junto Ribeira de Arronches, sua infância foi junto às aves brincado e sonhando em voar junto
        delas. Com 6 anos mudou-se para Portalegre. Em 2020, recebeu um drone. Como o meu sonho é real voo por cima do
        meu ninho (Portalegre) tentando mostrar a quem ainda nunca voou o encanto que é os meus sonhos.
      </div>
      <div className={classes.nascidoEm1979EmPortalegreInici}>
        <div className={classes.textBlock11}>
          Nascido em 1979, em Portalegre, iniciou-se na fotografia em 2006 como hobbie e, alguns anos mais tarde, 
          começou a fazer alguns trabalhos profissionalmente. Em 2018 compra o primeiro drone, para complementar os seus
          trabalhos fotográficos e é assim que começa a dedicar-se mais ao  video. Com a rápida aprendizagem e evolução
          a usar este equipamento, começa a fazer video e alguns diretos de programas televisivos.
        </div>
        <div className={classes.textBlock12}>
          Desde 2019 é parte integrante da Equipa Portuguesa que produz conteúdos para a Playboy a nível internacional,
          sendo um dos videografos oficiais na captação e edição de imagens de vídeo, terra e ar (com drone).
        </div>
      </div>
      <div className={classes._7ProfileAvatar}>
        <div className={classes.graphic}></div>
        <div className={classes.copy2}>
          <div className={classes.xicoFrancisco}>Xico Francisco</div>
        </div>
      </div>
      <div className={classes.graphic2}></div>
      <div className={classes.pedroBarradas}>Pedro Barradas</div>
      <div className={classes.graphic3}></div>
      <div className={classes.joaoMartins}>João Martins</div>
    </div>
  );
});
